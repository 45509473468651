<template>
  <div>
    <h4 class="my-4">Belgeler</h4>
    <div
      class="d-flex align-items-center justify-content-between search-list-area"
    >
      <div class="d-flex align-items-center search-input mb-4">
        <input placeholder="Ara.." v-model="search" class="form-control mr-2" />
        <select v-model="list_length" class="form-control ml-2">
          <option value="" selected>Seçiniz</option>
          <option value="5">5</option>
          <option value="10">10</option>
          <option value="20">20</option>
          <option value="50">50</option>
        </select>
      </div>
      <div class="mb-4">
        <b-button
          variant="outline-primary"
          class="ml-4"
          v-b-toggle.sidebar-right
          >Filtrele</b-button
        >
      </div>
    </div>

    <div class="">
      <List
        :header="header"
        :data="items"
        :pagination="pagination"
        :loading="loading"
        @updatePage="updatePage"
        moduleName="Belgeler Listesi"
        :moduleCreateEvent="true"
        :moduleSearch="search"
      >
        <template v-slot:item.file="{ item }">
          <a :href="`/image/${item.file}`" target="_rasim">Dosyayı Görüntüle</a>
        </template>

        <template v-slot:item.action="{ item }" class="p-0">
          <div class="d-flex justify-content-end">
            <b-button
              variant="light"
              @click="editModalOptions(item)"
              ><i class="fas fa-edit p-0"></i
            ></b-button>
            <b-button
              variant="light"
              class="ml-2"
              @click="openDeleteItemModal()"
              ><i class="fas fa-trash p-0"></i
            ></b-button>
          </div>
        </template>
      </List>
      <!-- Create and Actions Popups -->
    </div>
    <DeleteOperation modalid="deleteDocument" />
    <b-modal v-model="editModal" title="Belge Düzenle">
      <div class="row">
        <div class="col-12">
          <div class="form-group">
            <label for="document">Belge Adı</label>
            <input
              type="text"
              class="form-control"
              :value="this.editItem.document"
            />
          </div>
        </div>
        <div class="col-12">
          <div class="form-group">
            <label for="document">Belge</label>
            <b-form-file
            id="file"
            class="rounded-sm"
            placeholder="Dosya seçiniz"
            drop-placeholder="Dosyayı buraya sürükleyebilirsiniz"
            :value="this.editItem.file"
          ></b-form-file>
          </div>
        </div>
      </div>
      <template #modal-footer>
        <button class="btn btn-success" @click="editModal = false">Kaydet</button>
        <button class="btn btn-secondary" @click="editModal = false">Çık</button>
      </template>
    </b-modal>
  </div>
</template>

<style lang="scss" scoped>
@media (max-width: 990px) {
  .search-list-area {
    flex-direction: column;
    .search-input {
      width: 100%;
      * {
        width: 100%;
      }
    }
  }
  .search-buttons {
    flex-direction: column;
  }
}

.badges-area {
  display: flex;
  gap: 1rem;
  p {
    font-size: 14px !important;
    font-weight: 600 !important;
    color: $dveb-primary !important;
  }
  .badges {
    display: flex;
    align-items: center;
    gap: 0.2rem;
    .badge {
      display: inline-block;
      width: 1rem;
      height: 1rem;
      border-radius: 50%;
      padding: 0 !important;

      &.green {
        background-color: #44b6ae;
      }
      &.yellow {
        background-color: #ffac00;
      }
      &.red {
        background-color: red;
      }
    }
  }
}
.tapu-text {
  span {
    background-color: $dveb-divider;
    padding: 0.2rem 0.5rem;
    border-radius: 0.3rem;
  }
}
</style>

<script>
import moment from "moment";
moment.locale("tr");

export default {
  name: "customerList",

  data() {
    return {
      search: "",
      filters: "",
      showInsertUpdate: false,
      showDetail: false,
      showImportModal: false,
      resetForm: false,
      confirmCreate: false,
      loading: false,
      header: [
        { text: "#", value: "id", sortable: true, size: "60px" },
        { text: "Belge Adı", sortable: true, value: "document" },
        { text: "Eklenme Tarihi", sortable: true, value: "addDate" },
        { text: "Düzenlenme Tarihi", sortable: true, value: "updateDate" },
        { text: "Dosya", value: "file" },
        { text: "İşlemler", value: "action" },
      ],
      items: [],
      pagination: {},
      meta: {},
      listSelectedItemIds: [],
      selectedItem: "",
      filterList: [],
      list_length: 5,
      editModal: false,
      editItem: {},
    };
  },

  created() {
    this.search = this.$route.query.search;
    this.getList();
  },
  methods: {
    getList() {
      this.loading = true;
      this.items = [
        {
          id: 1,
          document: "Rasim",
          addDate: moment().format("LLL"),
          updateDate: moment().format("LLL"),
          file: "ilan1.png",
        },
      ];
      this.totalItems = this.items.length;

      this.pagination = {
        current_page: 1,
        total_pages: Math.ceil(this.items?.length / this.list_length),
        total_items: this.items.length,
        list_length: this.list_length,
      };
      this.loading = false;
      if (this.search) {
        const searchValue = this.search;
        const foundItems = this.items.filter((item) => {
          for (const prop in item) {
            if (item[prop].toString().toLowerCase().includes(searchValue)) {
              return true;
            }
          }
          return false;
        });
        this.items = foundItems;
      }

      // this.items = [];
      // this.totalItems = 0;

      // let queryLimit = this.perPage ? this.perPage : 10;
      // let query = `user/list?Page=${
      //   this.page ? this.page : 1
      // }&limit=${queryLimit}`;
      // if (this.search) {
      //   query += `&search=${this.search}`;
      // }

      // this.$dbFunctions
      //   .get(query)
      //   .then((res) => {
      //     if (res.success == true && res.count > 0) {
      //       this.items = res.data;
      //       this.totalItems = res.count;

      //       this.pagination = {
      //         current_page: res.page,
      //         total_pages: res.pageCount,
      //         total_items: res.count,
      //       };
      //     }
      //   })
      //   .catch((err) => {
      //     console.log(err);
      //   })
      //   .finally(() => {
      //     this.loading = false;
      //   });
    },
    updatePage(value) {
      this.meta = value;

      window.scrollTo({
        top: 0,
        behavior: "smooth",
      });
    },
    openDeleteItemModal() {
      this.$bvModal.show("modal-deleteOperationdeleteDocument");
    },
    editModalOptions(item) {
      this.editItem = item;
      this.editModal = true;
    },
  },
  watch: {
    meta: {
      handler() {
        this.getList();
      },
      deep: true,
    },
    search: function (params) {
      this.getList();
    },
    list_length: function () {
      this.getList();
    },
  },
};
</script>
